.zt-three-dot-loader {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex: 1 1 auto;
}

.zt-three-dot-loader div {
  height: .5rem;
  width: .5rem;
  border-radius:50%;
  transform: scale(0);
  background-color: #FFF;
  display: inline-block;
  margin: 4px;
  animation: scaling 1.5s ease-in-out infinite;
}

.zt-three-dot-loader div:nth-child(1){
  animation-delay:0s;
}
.zt-three-dot-loader div:nth-child(2){
  animation-delay:0.2s;
}
.zt-three-dot-loader div:nth-child(3){
  animation-delay:0.4s;
}

@keyframes scaling{
  0%, 100%{
    transform: scale(0.2);
  }
  40%{
    transform: scale(1);
  }
  50%{
    transform: scale(1);
  }
}


.loader-container {
  backdrop-filter: blur(2px);
  z-index: 3;
  @apply absolute top-0 left-0 bottom-0 right-0 bg-transparent flex items-center justify-center;
}

.lds-spinner {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;

  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #979797;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


h1 {
	font-size: 14px;
	font-weight: 400;
}

.loader {
	text-align: center;

	background: linear-gradient(to right, #73ADC4 0%, #31667f 20%, #14262f 40%, #31667f 60%, #73ADC4 80%);
	background-size: 200% auto;

	color: #000;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

	animation: shine 1.2s linear infinite;
}

@keyframes shine {
	to {
		background-position: -200% center;
	}
}

.container{
	border-radius:5px;
	display:flex;
	margin-bottom:6px;
	margin-left:8px;
}
.dot {
	margin-right:2px;
	width: 3.5px;
	height: 3.5px;
	border-radius: 50%;
	background-color:#73ADC4;
}


.container .dot:nth-last-child(1) {
	animation: jumpingAnimation 1.2s 0.3s linear infinite;
}
.container .dot:nth-last-child(2) {
	animation: jumpingAnimation 1.2s 0.2s linear infinite;
}
.container .dot:nth-last-child(3) {
	animation: jumpingAnimation 1.2s 0s linear infinite;
}

@keyframes jumpingAnimation {
	0% {
		transform: translate(0, 0);
		background-color:#73ADC4;
	}
	16% {
		transform: translate(0, -6px);
		background:#31667f;
	}
	33% {
		transform: translate(0, 0);
		background:#14262f;
	}
	100% {
		transform: translate(0, 0);
		background-color:#73ADC4;
	}
}
