.zt-vmeet-container {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
}
  
.zt-vmeet-container .zt-vmeet {
  flex: 1;
}
  
.zt-vmeet-container iframe {
  height: 100%;
  width: 100%;
}
  