@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');


@layer base {
  :root {
    --color-secondary: 49, 102, 127
  }
}

body {
  margin: 0;
  font-family: "Manrope", "Lato", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: var(--color-secondary) #f1f1f1;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* ::-webkit-scrollbar-track { 
  background-color: #f1f1f1;
} */

::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-secondary;
}

textarea:focus {
  outline-style: auto;
  outline-width: 1px;
}

.calendar {
  @apply block relative w-full bg-white overflow-hidden;
}

.swipe-right {
  animation: swipe-r 0.3s ease-in-out;
}

.swipe-left {
  animation: swipe-l 0.3s ease-in-out;
}

@keyframes swipe-r {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes swipe-l {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.number-input {
  border: 1px solid #8f8f8f !important;
  border-radius: 8px !important;
  width: 100% !important;
  padding: 10px 14px !important;
  padding-left: 60px !important;
  color: #1c1d21 !important;
  transition: none !important;
  outline-offset: 0px !important;
}

.number-input-error {
  border: 1px solid #ef4444 !important;
}

.number-input:focus {
  border: 1px solid #8f8f8f !important;
  box-shadow: none !important;
}

.number-input:focus-visible {
  outline: 1px auto var(--color-secondary) !important;
}

.number-input-error:focus {
  border: 1px solid #ef4444 !important;
  box-shadow: none !important;
}

.number-input-error:focus-visible {
  outline: 1px auto #ef4444 !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.zt-autocomp-select__control {
	border: 1px solid #8f8f8f !important;
	box-shadow: none !important;
}

.zt-autocomp-select__control:hover {
	border: 1px solid rgb(var(--color-secondary)) !important; 
}

.zt-autocomp-select__control:focus {
	border: 1px solid rgb(var(--color-secondary)) !important; 
}

.zt-autocomp-select__input {
  border: none !important;
  box-shadow: none !important;
}

.zt-autocomp-select__value-container {
	padding: 4px 8px !important;
}

.zt-autocomp-select__menu-list {
	max-height: 150px !important;
}

.zt-autocomp-select__menu-portal {
	z-index: 99999999 !important;
}

.zt-autocomp-select__option:hover {
	background-color: rgb(var(--color-secondary));
	opacity: 0.5;
	color: white;
}
